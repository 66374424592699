<template>
  <section>
    <!-- form-geral -->
    <form @submit.prevent="saveProduto">
      <div class="card card_border">
        <div class="cards__heading">
          <h3>Dados Gerais</h3>
        </div>
        <div class="card-body">
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">marca (montadora)</label>
              <y-select
                  v-model="form.marca_id"
                  :selected="form.marca_id"
                  :options="marcas"
                />
              
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">veiculo</label>
              <input
                type="text"
                class="form-control input-style"
                id="inputEmail4"
                placeholder="veiculo"
                name="veiculo" v-model="form.veiculo"
              />
              
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">lado</label>
              <input
                type="text"
                class="form-control input-style"
                id="inputEmail4"
                placeholder="lado"
                name="lado" v-model="form.lado"
              />
              
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">ano de aplicação inicial</label>
              <input
                type="text"
                class="form-control input-style"
                id="inputEmail4"
                placeholder="ano"
                name="ano_inicial" v-model="form.ano_inicial"
              />
              
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">ano de aplicação final</label>
              <input
                type="text"
                class="form-control input-style"
                id="inputEmail4"
                placeholder="ano"
                name="ano_final" v-model="form.ano_final"
              />
              
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">referencia</label>
              <input
                type="text"
                class="form-control input-style"
                id="inputEmail4"
                placeholder="referencia"
                name="referencia" v-model="form.referencia"
              />
              
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">descricao</label>
              <input
                type="text"
                class="form-control input-style"
                id="inputEmail4"
                placeholder="descricao"
                name="descricao" v-model="form.descricao"
              />
              
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">obs</label>
              <textarea
                class="form-control input-style"
                id="inputEmail4"
                placeholder="obs"
                name="obs" v-model="form.obs"
              />
              
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">imagem 1</label>
              <image-upload
                v-model="form.imagem_1"
              />
              
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">imagem 2</label>
              <image-upload
                v-model="form.imagem_2"
              />
              
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">imagem 3</label>
              <image-upload
                v-model="form.imagem_3"
              />
              
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">imagem 4</label>
              <image-upload
                v-model="form.imagem_4"
              />
              
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">imagem 5</label>
              <image-upload
                v-model="form.imagem_5"
              />
              
            </div>
          </div>
        </div>
        <div class="card-footer">

          <button type="submit" class="btn btn-primary btn-style">
            Salvar
          </button>
          
          <button class="btn btn-link"
            @click="()=>$router.push({ name: `${currentMeta.base}.search` })">
            Voltar
          </button>

        </div>
      </div>
    </form>
    <!-- /form-geral -->
  </section>
</template>

<script>

import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";

import useCommon from "@/common";
import useProduto from '@/composables/Produto';
// import useTipo from '@/composables/Tipo';
import useMarca from '@/composables/Marca';
// import useVeiculo from '@/composables/Veiculo';

export default {
  
  setup() {
    
    const { openToast } = useCommon();
    const { status, message, error, produto, storeProduto } = useProduto();

    // const { tipos, getTipos } = useTipo();
    const { marcas, getMarcas } = useMarca();
    // const { veiculos, getVeiculos } = useVeiculo();

    const router = useRouter();
    const currentMeta = router.currentRoute.value.meta;

    const showMessages = () => {
      if(status.value){
        openToast(message.value, 'success');
      }else{
        openToast(error.value, 'error');
      }
    };

    //
    const form = reactive({
      'marca_id': '',
      'veiculo': '',
      'lado': '',
      'ano_inicial': '',
      'ano_final': '',
      'referencia': '',
      'descricao': '',
      'obs': '',
      'imagem_1': '',
      'imagem_2': '',
      'imagem_3': '',
      'imagem_4': '',
      'imagem_5': '',
    });
    const saveProduto = async () => {
      await storeProduto({...form});
      showMessages();
      if(status){
        await router.push({name:'app.produto.add'});
      }
    }

    onMounted(function(){
      // getProduto(props.id);

      // getTipos(-1);
      getMarcas(-1);
      // getVeiculos(-1);
    });

    return {
      form,
      produto,
      saveProduto,
      currentMeta,

      // tipos,
      marcas,
      // veiculos,
    };
  },
};
</script>
